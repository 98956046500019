import React from "react";
import PropTypes from "prop-types";

class LabelItem extends React.Component {
    render() {
        return (
            <li className={this.props.className}>
                <label className={`${this.props.label} label-item`}>{this.props.label}</label>
                {this.props.children}
            </li>
        );
    }
}

LabelItem.displayName = "LabelItem";

LabelItem.propTypes = {
    class: PropTypes.array,
    label: PropTypes.string.isRequired,
};

LabelItem.defaultProps = {
    class: [],
};

export default LabelItem;
